const endpoint = "https://qvpb.rekopgames.com"
const mediapoint = "https://cb.rekopgames.com"

/* SOCKET TOKEN */
const DEALER_SOCKET_TOKEN = "ba109760-d019-486a-b4b1-c6075c01c092"

/* SOCKET TOKEN */
const DEALER_MONITOR_TOKEN = "499d019d-4042-4bcd-af34-512527bb6388"

/* POINTS */
const startpoint = `${endpoint}/api/`


export { endpoint, mediapoint, startpoint, DEALER_SOCKET_TOKEN, DEALER_MONITOR_TOKEN }
