import React, { Component } from "react"

/* REST API */
import { login } from '../api/Dealer'

/* SweetAlert */
import Swal from 'sweetalert2'

/* JWT */
import { decodeToken } from "react-jwt"

/* Fields */
const VALUES = [1, 2, 3, 4, 5, 6]




/* Page PIN */
class Pin extends Component {

    constructor() {
        super()

        this.state = {
            values: [],
            loading: false
        }
    }


    /* Add number */
    add = number => {

        /* Fields */
        const { setToken, socket } = this.props
        const { values } = this.state
        let codes = values

        /* Adding code */
        codes.push(number)

        /* Just save if PIN length less than 6 */
        if (codes.length <= 6) {
            this.setState({ values: codes })
        }

        /* Start Handle if PIN length equal to 6 */
        if (codes.length === 6) {

            /* Show loader */
            this.setState({ loading: true })

            /* To string from array */
            const pincode = codes.join("")


            /* REST API */
            login({ pincode }).then(response => {
                if (response.status === 200) {

                    const token = response.data.token
                    const dealer = decodeToken(token)

                    /* SEND DEALER INFO */
                    socket.emit("dealerInfo", { id: dealer.id, name: dealer.name })

                    /* Save in Storage */
                    setToken(token)

                }
                else {

                    /* Show Notification */
                    Swal.fire({
                        html: '<p class="pincode-error">Неверный PIN код!</p>',
                        backdrop: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 1500,
                        background: 'white'
                    })

                }
            }).catch(() => {

                /* Show Notification */
                Swal.fire({
                    html: '<p class="pincode-error">Ошибка! Что-то пошло не так ...</p>',
                    backdrop: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 1500,
                    background: 'white'
                })

            }).finally(() => {

                /* Finally clear all data */
                this.setState({ loading: false, values: [] })
            })
        }
    }


    /* Remove number */
    remove = () => {
        let { values } = this.state
        if (values.length > 0) {
            values.pop()
            this.setState({ values })
        }
    }


    /* Draw loading part */
    _loader = () => {

        /* Fields */
        const { loading } = this.state

        return (
            <div style={{ display: loading ? 'flex' : 'none' }} className='modal'>
                <div className='modal-content'>
                    <div className='loader'></div>
                    <div className='modal-text'>Загрузка ...</div>
                </div>
            </div>
        )
    }




    render = () => {

        const { values } = this.state

        return (
            <div className="pincode">

                {/* GAME LOGO */}
                <div className="pincode-logo">
                    <img src="/images/icon.png" alt="ICON" />
                    <div className="pincode-logo-text">
                        <span>VIP</span>
                        <p>QUEEN OF</p>
                        <p>SPADES</p>
                    </div>
                </div>

                {/* PIN CODE */}
                <div className="pincode-box">

                    <h1>Монитор дилера</h1>
                    <p>Введите PIN-код или отсканируйте карту, чтобы продолжить</p>

                    <div className="pincode-panel">

                        {/* VALUES */}
                        <div className="pincode-values">
                            {VALUES.map((_, index) =>
                                <div className={values.length >= index + 1 ? `pincode-active-value` : `pincode-value`} key={`${index}`} />
                            )}
                        </div>

                        {/* BUTTONS */}
                        <div className="pincode-buttons">
                            <div className="pincode-button" onClick={() => this.add("1")}>1</div>
                            <div className="pincode-button" onClick={() => this.add("2")}>2</div>
                            <div className="pincode-button" onClick={() => this.add("3")}>3</div>
                        </div>
                        <div className="pincode-buttons">
                            <div className="pincode-button" onClick={() => this.add("4")}>4</div>
                            <div className="pincode-button" onClick={() => this.add("5")}>5</div>
                            <div className="pincode-button" onClick={() => this.add("6")}>6</div>
                        </div>
                        <div className="pincode-buttons">
                            <div className="pincode-button" onClick={() => this.add("7")}>7</div>
                            <div className="pincode-button" onClick={() => this.add("8")}>8</div>
                            <div className="pincode-button" onClick={() => this.add("9")}>9</div>
                        </div>
                        <div className="pincode-buttons">
                            <div className="pincode-empty" />
                            <div className="pincode-button" onClick={() => this.add("0")}>0</div>
                            <div className="pincode-remove" onClick={() => this.remove()}>
                                <img src="/images/delete.png" alt="REMOVE" />
                            </div>
                        </div>

                    </div>

                </div>

                {this._loader()}

            </div>
        )
    }


}

export default Pin